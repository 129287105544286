<template>
  <base-layout :title="title" :backLink="backLink" :reloadable="reloadable">
    <ion-grid v-if="resource">
      <ion-row>
        <ion-col>
          <ion-list>
            <ion-list-header>
              Status: &nbsp;
              <ion-text
                ><b> {{ exam_status ? "Ready" : "Evaluating" }} </b></ion-text
              >
              <ion-button
                :color="exam_status ? 'success' : 'danger'"
                fill="solid"
                style="margin-left: auto"
                @click="checkReportCardStatus()"
              >
                <ion-icon
                  :ios="downloadOutline"
                  :md="downloadSharp"
                  size="small"
                  slot="start"
                ></ion-icon
                >Download</ion-button
              >
            </ion-list-header>

            <ion-item lines="none">
              <ion-label>User name: {{ user.username }}</ion-label>
            </ion-item>
            <ion-item lines="full">
              <ion-label slot="start"
                >Class: {{ user.student.section_standard.standard.name }} -
                {{ user.student.section_standard.section.name }}</ion-label
              >
              <ion-text slot="end"
                >Roll No.: {{ user.student.roll_no }}</ion-text
              >
            </ion-item>

            <ion-list-header> Exam Result </ion-list-header>
            <ion-item
              lines="none"
              v-for="item in studentExamResult"
              :key="item.id"
            >
              <ion-note slot="start" color="dark">
                <b>
                  {{ item.exam_subject.subject.name }}
                  {{ exam_status ? "" : `(${item.exam_subject_state.name})` }}
                </b>
              </ion-note>
              <ion-label class="ion-text-end">
                <!-- {{ item.marks_secured }} / {{ item.exam_subject.full_mark }} -->
              </ion-label>
            </ion-item>
          </ion-list>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-grid v-else>
      <ion-row>
        <ion-col> Not found! please check again later </ion-col>
      </ion-row>
    </ion-grid>
  </base-layout>
</template>

<script>
import BaseLayout from "../../Base/BaseLayout";
import base_url from "../../../apis/base_url";

import {
  alertController,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonListHeader,
  IonItem,
  IonLabel,
  IonText,
  IonNote,
  IonButton,
  IonIcon,
} from "@ionic/vue";

import { downloadOutline, downloadSharp } from "ionicons/icons";

import moment from "moment";

export default {
  props: ["exam", "title"],

  components: {
    // IonIcon,
    BaseLayout,
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonListHeader,
    IonItem,
    IonLabel,
    IonText,
    IonNote,
    IonButton,
    IonIcon,
  },

  data() {
    return {
      backLink: "exam.list",
      reloadable: false,

      downloadOutline,
      downloadSharp,
      base_url,
      moment,

      xhrError: {
        header: null,
        description: null,
      },
    };
  },

  computed: {
    resource() {
      return this.$store.getters["admitCard/specificResource"];
    },

    studentExamResult() {
      return this.$store.getters["studentExamResult/allResources"];
    },

    user() {
      return this.$store.getters["auth/user"];
    },

    token() {
      return this.$store.getters["auth/token"];
    },

    exam_status() {
      if (!this.studentExamResult) {
        return false;
      } else {
        if (this.studentExamResult.length == 0) {
          return false;
        }
      }

      let exam_status = true;
      this.studentExamResult.forEach((element) => {
        if (element.exam_subject_state.name != "Locked") {
          exam_status = false;
        }
      });

      return exam_status;
    },
  },

  methods: {
    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });

      return alert;
    },

    setErrorResponse(error) {
      if (error.response) {
        this.xhrError.header = error.response.data.header;
        this.xhrError.message = error.response.data.message;
      } else if (error.request) {
        console.log(error.request);
        this.xhrError.header = error.message || error.request;
        this.xhrError.message = "Please check your connection and try again";
      } else {
        this.xhrError.header = error.message;
        this.xhrError.message = "Something went wrong. Try again later";
      }
    },

    downloadReportCard() {
      const exam_id = this.exam;
      const user_id = this.user.id;
      const url = `${this.base_url}api/v1/download-result-student?pat=${this.token}&exam_id=${exam_id}&user_id=${user_id}`;
      const new_download_button = document.createElement("a");
      new_download_button.href = url;
      new_download_button.download = "report_card.pdf";
      new_download_button.click();
    },

    async decline() {
      const alertDialog = await this.alerter(
        "Under Evaluation",
        "Exam is under evaluation. Please wait for a while",
        [
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
          },
        ]
      );
      alertDialog.present();
    },

    checkReportCardStatus() {
      if (this.exam_status) {
        this.downloadReportCard();
      } else {
        this.decline();
      }
    },

    async fetchResource() {
      await this.$store.dispatch("admitCard/specificResource", this.exam);
    },

    async fetchStudentExamResult() {
      await this.$store.dispatch("studentExamResult/allResources", this.exam);
    },
  },

  async mounted() {
    await this.fetchResource();
    await this.fetchStudentExamResult();
  },
};
</script>

<style scoped>
</style>
